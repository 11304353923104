import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../../images/logo.png'
import styles from "./Invoices.module.css"

import NavigationBar from '../Utils/NavigationBar'
import PopUpIndInvoice from './PopUpIndInvoice';
import PopUpAutomatic from './PopUpAutomatic';
import PopUpCreate from './PopUpCreate';
import PopUpTiers from './PopUpTiers';


const InvoicesPage = ({ param, formatDate, changeNavType, navType, allInvoices, searchControl, indPopUpControls, indInvoicePopup, automaticPopup, popUpControls, createPopup, tiersPopup }) => {
	return (
		<div className={`${styles.page_container} ${(indInvoicePopup || automaticPopup || createPopup || tiersPopup) && styles.hidden_container}`}>
			<div className={`${styles.sites_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType == 'Invoices' &&
						<div className={styles.dual_buttons}>
							<button onClick={() => popUpControls('open', false, true)}>
								<i className="fa-solid fa-layer-group"></i>
								<p className={styles.smaller_font}>Pricing tiers</p>
							</button>

							<button onClick={() => popUpControls('open', true)}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Custom invoice</p>
							</button>

							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Automatic invoices</p>
							</button>
						</div>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Invoices' && styles.sitenav_active}`} onClick={() => changeNavType('Invoices')}><p>Invoices</p></button>
						{/* <button className={`${styles.sitenav_box} ${navType == 'Queue' && styles.sitenav_active}`} onClick={() => changeNavType('Queue')}><p>Queue</p></button> */}
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => searchControl(event, "main_table")}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="main_table">						    
						    {navType == "Invoices" &&
						    	<>
							    <thead>
							      <tr className={styles.table_rowth}>
							      	<th>Invoice no.</th>
							      	<th>Company</th>
							        <th>Date issued</th>
							        <th>Due date</th>
							        <th>No. orders</th>
							        <th>Amount</th>
							        <th>Status</th>
							      </tr>
							    </thead>
						    	<tbody>

							    {(allInvoices && allInvoices.length > 0)
							    	?
							    	<>
									    {allInvoices.map((AI, key) => {
									    	return (
									    		<tr onClick={() => indPopUpControls('open', AI)} key={key}>
										    		<td>{AI.Number}</td>
										    		<td>{AI.Company}</td>
										    		<td>{formatDate(AI.Date)}</td>
										    		<td>{formatDate(AI.Terms.DueDate)}</td>
										    		<td>{AI.Lines.length} {AI.Lines.length > 1 ? 'orders': 'order'}</td>
										    		<td>${AI.TotalAmount}</td>
										    		<td className={styles.site_statustd}>
										    			<div className={`${styles.site_status} ${AI.Status == "Open" && styles.status_pending} ${AI.Status == "Closed" && styles.status_active} ${AI.Status == "Overdue" && styles.status_inactive}`}>
										    				<p className={styles.smaller_font}>{AI.Status}</p>
										    			</div>
										    		</td>
										    	</tr>
									    	)
										})}
									</>
									: <h1 className={styles.create_first}>There are no invoices available in MYOB.</h1>
								}
								</tbody>
								</>
							}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}


const Invoices = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [allInvoices, setAllInvoices] = useState(null);
	const [allAccounts, setAllAccounts] = useState(null);
	const [allTiers, setAllTiers] = useState(null);
	const [allSites, setAllSites] = useState(null);
	const [allOrders, setAllOrders] = useState(null);
	const [allInventory, setAllInventory] = useState(null);
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [navType, setNavType] = useState('Invoices');
 	const [indNav, setIndNav] = useState("Details");
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);

 	// ind invoices
 	const [indInvoicePopup, setIndInvoicePopup] = useState(false);
 	const [indInvoice, setIndInvoice] = useState(null);

 	// automatic
 	const [automaticPopup, setAutomaticPopup] = useState(false);
 	const [newInvoiceData, setNewInvoiceData] = useState({});
 	const [invoiceOrders, setInvoiceOrders] = useState(null);
 	const [invoiceOrdersOG, setInvoiceOrdersOG] = useState(null);

 	// custom invoice
 	const [createPopup, setCreatePopup] = useState(false);
 	const [allInvoiceData, setAllInvoiceData] = useState({});
 	const [createInvoiceData, setCreateInvoiceData] = useState([]);

 	// tiersPopup
 	const [tiersPopup, setTiersPopup] = useState(false);
 	const [popupNav, setPopupNav] = useState("Fruit");
 	const [procalTiers, setProcalTiers] = useState([])
 	const [fruitTiers, setFruitTiers] = useState([])
 	const [generalTierData, setGeneralTierData] = useState({})

	const param = useParams();
	const url = `/api/admin/invoices/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setAllInvoices(data.data.allInvoices)
					// setAllAccounts(data.data.allAccounts)
					// setAllSites(data.data.allSites)
					// setAllOrders(data.data.allOrders)
					setAllInventory(data.data.allInventory)
					setAllTiers(data.data.allTiers)
					setProcalTiers(data.data.allTiers.filter(tier => tier.location === "General").filter(tier => tier.type === "Milk")[0].items)
					setFruitTiers(data.data.allTiers.filter(tier => tier.location === "General").filter(tier => tier.type === "Fruit")[0].items)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url]);


	const searchControl = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById(tableId).children[1].children;

	    for (let i = 0; i < target_div.length; i++) {
	        let txtValues = [];
	        for (let j = 0; j < target_div[i].children.length; j++) {
                if (target_div[i].children[j].className.includes('status')) {
                    // handle status
                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
                    txtValues.push(txtValue.toUpperCase());
                } else {
	            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
	            	txtValues.push(txtValue.toUpperCase());
	            }
	        }

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "table";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	const searchInvoiceControl = (event) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById('invoice_table').children

	    for (let i = 0; i < target_div.length; i++) {
	    	let fullString = target_div[i].children[0].textContent

			let account = fullString.split(' - ')[0] ? fullString.split(' - ')[0] : ''
			let price = fullString.split(' - ')[1] ? fullString.split(' - ')[1] : ''
	        let txtValues = [account.toUpperCase(), price.toUpperCase()];

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "grid";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: options[o], value: options[o]})
		}

		return options_arr
	}

	const formatDate = (dateString, showDay, showTime) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    if (!showTime) {
	    	dateObj.setHours(0, 0, 0, 0);
	    }

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay && showTime) {
	        let shours = ('0' + dateObj.getHours()).slice(-2);
	        let sminutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let sseconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year} @ ${shours}:${sminutes}:${sseconds}`;

	    } else if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else if (showTime) {
	        let hours = ('0' + dateObj.getHours()).slice(-2);
	        let minutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let seconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${day}/${month}/${year} @ ${hours}:${minutes}:${seconds}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};

	const isDateInRange = (dateToCheck, startDate, endDate) => {
	  	const parsedDate = new Date(dateToCheck);
	  	const parsedStartDate = new Date(startDate);
	  	const parsedEndDate = new Date(endDate);

	  	return parsedDate >= parsedStartDate && parsedDate <= parsedEndDate;
	}

	const sortByCompany = (allOrders) => {
	    const output = {};

	    for (const order of allOrders) {
	        let groupKey = order.supplier_platform && order.supplier_platform !== "No" ? order.supplier_platform : order.company;

	        if (!output[groupKey]) {
	            output[groupKey] = [];
	        }

	        output[groupKey].push(order);
	    }

	    return output;
	}

	const changeNavType = (type) => {
		setNavType(type)
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}


	const popUpControls = async (control, custom=false, tiers=false) => {
		switch(control) {
			case 'open':
				switch (navType) {
					case 'Invoices':
						if (custom) {
							let invDup = [...createInvoiceData]
							let emptyInv = {
								order: 0,
								qty: '',
								item: '',
								description: '',
								discount: '',
								gst: '',
								amount: ''
							}

							invDup.push(emptyInv)
							setCreateInvoiceData(invDup)
							setCreatePopup(true)
						} else if (tiers) {
							setTiersPopup(true)
						} else {
							setAutomaticPopup(true)
						}
						break;
				}
				break;
			case 'prev':
				if (popUpPage == 2) {
					setNewInvoiceData({})
				}

				if (popUpPage == 3) {
					setInvoiceOrders(null)
					setInvoiceOrdersOG(null)
				}
				setPopUpPage(popUpPage-1);
				break;
			case 'next':
				switch (navType) {
					case 'Invoices':
						if (popUpPage == 1) {
							if (!(newInvoiceData.hasOwnProperty('invoice_condition'))) {
								setValidationError(`Please select one of the invoice condition options.`)
								return false;
							} else {
								if (newInvoiceData.invoice_condition == "invoice_selected") {
									setInternalLoading(true)
									const retrieved = await axios.get(`/api/admin/invoices/retrieveAllSites/${param._id}`, axios_config);
									setAllSites(retrieved.data.sites)
									setInternalLoading(false)
								}
							}
						}

						if (popUpPage == 2) {
							if (newInvoiceData.invoice_condition == "invoice_all") {
								if (!(newInvoiceData.hasOwnProperty('invoice_cycle'))) {
									setValidationError(`Please select one of the invoice cycle options.`)
									return false;
								}
							} else if (newInvoiceData.invoice_condition == "invoice_selected") {
								if (!(newInvoiceData.hasOwnProperty('sites')) || newInvoiceData['sites'].length == 0) {
									setValidationError(`Please select at least 1 site.`)
									return false;
								}
							}
						}

						if (popUpPage == 3) {
							if (!(newInvoiceData.hasOwnProperty('invoice_date_range')) || newInvoiceData['invoice_date_range'] == '') {
								setValidationError(`Please select an invoice date range.`)
								return false;
							}

							// handle invoice_cycle
							if (newInvoiceData.invoice_condition == "invoice_all") {

								setInternalLoading(true)
								const url = `/api/admin/invoices/automaticInvoices/${param._id}`;
								const data = await axios.post(url, {
									date_range: [newInvoiceData.invoice_date_range[0], newInvoiceData.invoice_date_range[1]],
									invoice_cycle: newInvoiceData.invoice_cycle
								}, axios_config);

								setInternalLoading(false)

								if (data.data.ordersPushed.length > 0) {
									setInvoiceOrders(sortByCompany(data.data.ordersPushed))
									setInvoiceOrdersOG(sortByCompany(data.data.ordersPushed))
								}
							} else if (newInvoiceData.invoice_condition == "invoice_selected") {
								setInternalLoading(true)
								
								const url = `/api/admin/invoices/automaticInvoices/${param._id}`;
								const data = await axios.post(url, {
									date_range: [newInvoiceData.invoice_date_range[0], newInvoiceData.invoice_date_range[1]],
									invoice_cycle: newInvoiceData.invoice_cycle,
									sites: newInvoiceData['sites']
								}, axios_config);

								if (data.data.ordersPushed.length > 0) {
									setInvoiceOrders(sortByCompany(data.data.ordersPushed))
									setInvoiceOrdersOG(sortByCompany(data.data.ordersPushed))
								}

								setInternalLoading(false)
							}
						}

						setValidationError(null)
						setPopUpPage(popUpPage+1)
						break;
					}
				break
			case 'close':
				switch (navType) {
					case 'Invoices':
						if (createPopup) {
							const confirm_close = window.confirm(`Are you sure you would like to discard this new custom invoice?`)
							if (confirm_close) {
								setCreatePopup(false);

								setCreateInvoiceData([])
								setAllInvoiceData({})
								setPopUpPage(1)
								setValidationError(null)
							}
						} else if (tiersPopup) {
							const confirm_close = window.confirm(`Are you sure you would like to discard these new tiered pricing?`)
							if (confirm_close) {
								setTiersPopup(false);

								setGeneralTierData({})
								setPopUpPage(1)
								setValidationError(null)
							}
						} else {
							if (Object.keys(newInvoiceData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this new invoice generation?`)
								if (confirm_close) {
									setAutomaticPopup(false);

									setNewInvoiceData({})
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setAutomaticPopup(false);
								setValidationError(null)
							}
						}
						break;
				}
				break;
			case 'submit':
				// setIsLoading(true)
				switch (navType) {
					case 'Invoices':
						await axios.post(`/api/admin/invoices/automatic_invoices/${param._id}`, {
							invoiceOrders: invoiceOrders,
							fruitTiers: fruitTiers,
							procalTiers: procalTiers
						});
						break;
				}
				// window.location.reload(false);
				break;
		}
	}

	const indPopUpControls = async (control, ind) => {
		switch(control) {
			case 'open':
				switch (navType) {
					case 'Invoices':
						setIndInvoicePopup(true)
						setIndInvoice(ind)
						break;
				}
				break
			case 'edit':
				switch (navType) {
					case 'Invoices':
						break;
				}
				break
			case 'close':
				switch (navType) {
					case 'Invoices':
						setIndInvoicePopup(false)
						setIndNav("Details")
						setIndInvoice(null)
						break;
				}
				break;
			case 'pdf':
				switch (navType) {
					case 'Invoices':
						setInternalLoading(true)
						let server_response = await axios.post(`/api/admin/invoices/generate_pdf/${param._id}`, {
						  	invoiceData: indInvoice
						}, {
						  	responseType: 'arraybuffer' // Set the response type here
						});
						setInternalLoading(false)


						const pdfData = server_response.data;

						if (pdfData) {
							const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
							const pdfUrl = URL.createObjectURL(pdfBlob);

							const a = document.createElement('a');
							a.href = pdfUrl;
							a.target = '_blank';
							a.click();

							URL.revokeObjectURL(pdfUrl);
						} else {
							console.error('Error opening the PDF in a new tab');
						}
						break;
				}
				break;
			case 'navigate':
				switch (navType) {
					case 'Invoices':
// 						setIndInventoryPopup(false)
// 						setIndNav("Details")
// 						setIndInventory(null)
// 
// 						setNavType("Suppliers")
// 						setIndSuppliersPopup(true)
// 						setIndSuppliers(ind)
						break;
				}
				break;
		}
	}

	const handleNewInvoiceData = (name, input) => {
		if (name == "sites") {
			setNewInvoiceData({ ...newInvoiceData, [name]: input.map(i => i.value) });
		} else {
			setNewInvoiceData({ ...newInvoiceData, [name]: input.value });
		}
	};

	const createPopUpControls = async (control, order=null) => {
		switch(control) {
			case 'add':
				let createInvoiceDataDup = [...createInvoiceData]

				let emptyInvoiceRow = {
					order: createInvoiceDataDup[createInvoiceDataDup.length - 1].order + 1,
					qty: '',
					item: '',
					description: '',
					discount: '',
					gst: '',
					amount: ''
				}

				createInvoiceDataDup.push(emptyInvoiceRow)
				setCreateInvoiceData(createInvoiceDataDup)
				break
			case 'delete':
				const confirm_close = window.confirm(`Are you sure you would like to discard item ${order+1}?`)
				if (confirm_close) {
					let createInvoiceDataDup = [...createInvoiceData]
					let createInvoiceDataFinal = createInvoiceDataDup.filter(MA => MA.order !== order)

					for (let m=0; m < createInvoiceDataFinal.length; m++) {
						createInvoiceDataFinal[m].order = m
					}

					setCreateInvoiceData(createInvoiceDataFinal)
				}
				break;
			case 'submit':
				if (!(allInvoiceData.hasOwnProperty('customer')) || allInvoiceData['customer'] == '') {
					setValidationError(`Please select a customer.`)
					return false;
				}

				if (!(allInvoiceData.hasOwnProperty('invoice_contact_name')) || allInvoiceData['invoice_contact_name'] == '') {
					setValidationError(`Please enter the full name of the invoice contact.`)
					return false;
				}

				if (!(allInvoiceData.hasOwnProperty('recipient_email')) || allInvoiceData['recipient_email'] == '') {
					setValidationError(`Please enter the email of the recipient.`)
					return false;
				} else {
					if (!(isValidEmail(allInvoiceData.recipient_email))) {
						setValidationError(`Please enter a valid recipient email address.`)
						return false;
					}
				}

				if (!(allInvoiceData.hasOwnProperty('billing_address')) || allInvoiceData['billing_address'] == '') {
					setValidationError(`Please enter the billing address.`)
					return false;
				}

				if (!(allInvoiceData.hasOwnProperty('invoice_dates')) || allInvoiceData['invoice_dates'].length < 2 || allInvoiceData['invoice_dates'].includes(null)) {
					setValidationError(`Please select both the issued on date and the due date.`)
					return false;
				}


				for (let r=0; r < createInvoiceData.length; r++) {
					if (createInvoiceData[r].item == '') {
						setValidationError(`Please select an item in row ${r+1}.`)
						return false;
					}

					if (createInvoiceData[r].qty == '') {
						setValidationError(`Please enter the quantity in row ${r+1}.`)
						return false;
					}

					if (createInvoiceData[r].amount == '') {
						setValidationError(`Please enter the amount in row ${r+1}.`)
						return false;
					}
				}

				setInternalLoading(true)
				let server_response = await axios.post(`/api/admin/invoices/generate_invoice/${param._id}`, {
				  	invoiceData: allInvoiceData,
				  	invoiceLines: createInvoiceData
				});
				window.location.reload(false);
				break;
		}
	}

	const handleCreateInvoiceData = (name, input) => {
		if (name == "customer") {
			let customer_info = allAccounts.filter(AA => AA.company == input.value)[0]
			setAllInvoiceData({ ...allInvoiceData, [name]: input.value, invoice_contact_name: `${(customer_info.contacts.length === 3 && [2].hasOwnProperty('first_name')) ? customer_info.contacts[2].first_name : customer_info.contacts[0].first_name} ${(customer_info.contacts.length === 3 && [2].hasOwnProperty('last_name')) ? customer_info.contacts[2].last_name : customer_info.contacts[0].last_name}`, recipient_email: (customer_info.contacts.length === 3 && [2].hasOwnProperty('first_name')) ? customer_info.contacts[2].email : customer_info.contacts[0].email });
		} else if (name == "invoice_dates") {
			setAllInvoiceData({ ...allInvoiceData, [name]: input });
		} else {
			setAllInvoiceData({ ...allInvoiceData, [name]: input.target.value });
		}
	};

	const handleInvoiceRowData = (name, input=null, order) => {
		let invoiceDataDup = [...createInvoiceData]
		let invoiceDataInd = {...invoiceDataDup[order]}

		if (name == "item") {
			invoiceDataInd[name] = input.value

			let indItem = allInventory.filter(AI => AI.item_name == input.value)[0]

			if (invoiceDataInd.qty != '') {
				invoiceDataInd['amount'] = indItem['sale_price'] && indItem['sale_price'] * Number(invoiceDataInd.qty).toFixed(2);
			}

			invoiceDataInd['gst'] = indItem.gst ? '10' : '0'


		} else if (name == "qty") {
			invoiceDataInd[name] = input.target.value

			if (invoiceDataInd.item != '') {
				let indItem = allInventory.filter(AI => AI.item_name == invoiceDataInd.item)[0]

				invoiceDataInd['amount'] = (indItem['sale_price'] && indItem['sale_price'] * Number(input.target.value)).toFixed(2);
				
			}
		} else {
			invoiceDataInd[name] = input.target.value
		}

		invoiceDataDup[order] = invoiceDataInd
		setCreateInvoiceData(invoiceDataDup)
	}

	const onDragEndItems = (result) => {
		if (!result.destination) {
	  		return;
		}

		const items = [...createInvoiceData];
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		for (let m=0; m < items.length; m++) {
			items[m].order = m
		}

		setCreateInvoiceData(items);
	};


	const changePopupNav = (direction) => {
		setPopupNav(direction)
	}

	const handleMainTier = async (control, name=null, sel=null) => {
		switch(control) {
			case 'next':
				if (!generalTierData.hasOwnProperty('condition') || generalTierData.condition === "") {
					setValidationError("Please select a tier condition.")
					return false;
				}

				setValidationError(null)
				setPopUpPage(popUpPage+1)
				break;
			case 'prev':
				setGeneralTierData({})
				setPopUpPage(popUpPage-1)
				break;
			case 'set':
				let generalTierDataDup = { ...generalTierData }
				generalTierDataDup[name] = sel

				if (sel === "general") {
					setProcalTiers(allTiers.filter(tier => tier.location === "General").filter(tier => tier.type === "Milk")[0].items)
					setFruitTiers(allTiers.filter(tier => tier.location === "General").filter(tier => tier.type === "Fruit")[0].items)
				} else if (sel === "yordar") {
					setProcalTiers(allTiers.filter(tier => tier.location === "Yordar").filter(tier => tier.type === "Milk")[0].items)
					setFruitTiers(allTiers.filter(tier => tier.location === "Yordar").filter(tier => tier.type === "Fruit")[0].items)	
				} else if (sel === "eatfirst") {
					setProcalTiers(allTiers.filter(tier => tier.location === "EatFirst").filter(tier => tier.type === "Milk")[0].items)
					setFruitTiers(allTiers.filter(tier => tier.location === "EatFirst").filter(tier => tier.type === "Fruit")[0].items)	
				}

				setGeneralTierData(generalTierDataDup)
				break;
		}
	}

	const tierControls = async (type, control, index=null, name=null, input=null) => {
		switch (control) {
			case 'add':
				setProcalTiers([...procalTiers, {
					item: '',
					tier1: '',
					tier2: '',
					tier3: '',
					tier4: '',
					tier5: '',
				}])
				break;
			case 'delete':
				let procalTiersDup = [...procalTiers]
				let spliced = procalTiersDup.splice(index, 1)
				setProcalTiers(procalTiersDup)
				break;
			case 'set':
				if (type === "milk") {
					let procalTiersDupSet = [...procalTiers]
					if (name === "item") {
						procalTiersDupSet[index][name] = input.value
					} else {
						procalTiersDupSet[index][name] = input.target.value
					}

					setProcalTiers(procalTiersDupSet)
				} else {
					let fruitTiersDup = [...fruitTiers]
					fruitTiersDup[index][name] = input.target.value

					setFruitTiers(fruitTiersDup)
				}
				break;
			case 'submit':
				let server_response = await axios.post(`/api/admin/invoices/tiers/${param._id}`, {
					type: type,
					location: generalTierData.condition,
				  	tierData: type === "milk" ? procalTiers : fruitTiers
				});
				window.location.reload(false);
				break;
		}
	}

	const groupInvoiceOrders = (invoiceOrders) => {
		const groupedOrders = invoiceOrders.reduce((acc, order) => {
		    const site = order.site_name;
		    if (!acc[site]) {
		        acc[site] = [];
		    }
		    acc[site].push(order);
		    return acc;
		}, {});

		return groupedOrders
	}

	const sortInvoiceOrders = (invoiceOrders) => {
		invoiceOrders.sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));

		return invoiceOrders
	}

	return (
		<>
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Invoices"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<InvoicesPage {...{ param, formatDate, changeNavType, navType, allInvoices, searchControl, indPopUpControls, indInvoicePopup, automaticPopup, popUpControls, createPopup, tiersPopup }} />
								
								{indInvoicePopup && <PopUpIndInvoice {...{ param, indPopUpControls, indInvoice, indNav, changeIndNav, internalLoading, formatDate, searchControl }} />}
								{automaticPopup && <PopUpAutomatic {...{ param, popUpPage, internalLoading, popUpControls, validationError, handleNewInvoiceData, newInvoiceData, formatOptions, allSites, invoiceOrders, invoiceOrdersOG, allAccounts, formatDate, allInventory, sortInvoiceOrders, allOrders, allTiers, groupInvoiceOrders, searchInvoiceControl }} />}

								{createPopup && <PopUpCreate {...{ param, popUpControls, indNav, changeIndNav, internalLoading, formatOptions, allAccounts, createPopUpControls, createInvoiceData, allInventory, handleCreateInvoiceData, allInvoiceData, handleInvoiceRowData, onDragEndItems, validationError }} />}
								{tiersPopup && <PopUpTiers {...{ param, popUpControls, popUpPage, indNav, changeIndNav, internalLoading, formatOptions, allAccounts, createPopUpControls, createInvoiceData, allInventory, handleCreateInvoiceData, allInvoiceData, handleInvoiceRowData, onDragEndItems, validationError, popupNav, changePopupNav, procalTiers, allInventory, tierControls, fruitTiers, handleMainTier, generalTierData }} />}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Invoices"} />
			}
		</>
	);
};


export default Invoices;
