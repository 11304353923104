import styles from "./Warehouse.module.css"
import ResizableDiv from '../Utils/ResizableDiv';

const PopUpIndDelivery = ({ param, indPopUpControls, indDelivery, indNav, changeIndNav, formatDate, nextDeliveryDate, handleRunSheet, handleWorkbookPacking, handleWorkbookContacts, allSites, internalLoading, runPopup, searchControl, mapLoading, boxModelShowing, showIndModel, handleDeliveryRecords, indLoading }) => {
	return (
		<div className={`${styles.indsite_container} ${runPopup && styles.hidden_indcontainer}`}>
			<ResizableDiv className="indsite_popup" startingWidth="40">
				{indLoading
					?
						<div className={styles.loadpage_container}>
							<div className={styles.load}>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					:
						<div className={styles.indsite_inner}>
							<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
							<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
							<button className={styles.deletePopup} onClick={() => indPopUpControls('delete', indDelivery)}><i className="fa-solid fa-trash-can"></i></button>

							<h1 className={styles.medium_font}>Delivery details</h1>
							<p className={styles.smaller_font}>Please find the specific delivery details for this run here.</p>

							<div className={styles.indsite_nav}>
								<div className={styles.sitenav}>
									<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
									<button className={`${styles.sitenav_box} ${indNav == 'Run' && styles.sitenav_active}`} onClick={() => changeIndNav('Run')}><p>Run</p></button>
									<button className={`${styles.sitenav_box} ${indNav == 'Map' && styles.sitenav_active}`} onClick={() => changeIndNav('Map')}><p>Map</p></button>
									<button className={`${styles.sitenav_box} ${indNav == 'Contacts' && styles.sitenav_active}`} onClick={() => changeIndNav('Contacts')}><p>Contacts</p></button>
									<button className={`${styles.sitenav_box} ${indNav == 'Records' && styles.sitenav_active}`} onClick={() => changeIndNav('Records')}><p>Records</p></button>
									{/* <button className={`${styles.sitenav_box} ${indNav == 'Box Models' && styles.sitenav_active}`} onClick={() => changeIndNav('Box Models')}><p>Box Models</p></button> */}
								</div>
							</div>

							{indNav == "Details" &&
								<>
								{internalLoading
									?
									<div className={styles.loadpage_container}>
										<div className={styles.load}>
											<span></span>
											<span></span>
											<span></span>
											<span></span>
										</div>
									</div>
									:
									<div className={styles.sites_tablecontainer}>
										<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Run day</label>
												<p>{indDelivery.run_day}</p>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Run number</label>
												<p>Run {indDelivery.run_number}</p>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>No. Deliveries</label>
												<p>{indDelivery.items.length} deliveries</p>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Next delivery</label>
												<p>{formatDate(nextDeliveryDate(indDelivery.run_day), true)}</p>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Run sheet</label>
												<button className={styles.export_btn} onClick={() => handleRunSheet('open')}>
													<i className="fa-solid fa-file-export"></i>
													Export sheet
												</button>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Packing sheets</label>
												<button className={styles.export_btn} onClick={() => handleWorkbookPacking()}>
													<i className="fa-solid fa-file-export"></i>
													Export sheet
												</button>
											</div>
										</div>
									</div>
								}
								</>
							}

							{indNav == "Run" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
										<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
											<div className={styles.sites_search}>
												<i className="fa-solid fa-magnifying-glass"></i>
												<input
												    type="text"
												    className={styles.smaller_font}
												    placeholder="Search for any of the column headings..."
												    onKeyUp={(event) => searchControl(event, "ind_table")}
												/>
											</div>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th>Site</th>
										      	<th>References</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{indDelivery.items
										    		?
										    		<>
										    			{indDelivery.items.length > 0
										    				? 
										    				<>
											    				{indDelivery.items.map((ID) => {
											    					console.log(ID)
											    					return (
																    	<tr className={styles.sitesind_tr}>
																    		<td>{ID.site_name}</td>
																    		<td>{}</td>
																    	</tr>
															    	)
															    })}
														    </>
										    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
										    			}
										    		</>
										    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}

							{indNav == "Map" &&
								<div className={styles.map_full}>
									{mapLoading &&
										<div className={styles.loadpage_container}>
											<div className={styles.load}>
												<span></span>
												<span></span>
												<span></span>
												<span></span>
											</div>
										</div>
									}

									<div className={`${styles.map_outer} ${mapLoading && styles.hide_map}`}>
										<div id="map"></div>
									</div>
								</div>
							}

							{indNav == "Contacts" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
										<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
											<div className={styles.sites_search}>
												<i className="fa-solid fa-magnifying-glass"></i>
												<input
												    type="text"
												    className={styles.smaller_font}
												    placeholder="Search for any of the column headings..."
												    onKeyUp={(event) => searchControl(event, "ind_table")}
												/>
											</div>
										</div>

										<button className={styles.indsitebtn} onClick={handleWorkbookContacts}>
											<i className="fa-solid fa-file-export"></i>
											<p className={styles.smaller_font}>Export</p>
										</button>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th>Site</th>
										      	<th>Name</th>
										      	<th>Phone</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{indDelivery.items
										    		?
										    		<>
										    			{indDelivery.items.length > 0
										    				? 
										    				<>
											    				{indDelivery.items.map((ID) => {
											    					return (
																    	<tr className={styles.sitesind_tr}>
																    		<td>{ID.site_name}</td>
																    		{/* <td>{allSites.filter(AS => AS.site_name == ID.site_name)[0].site_contact.first_name} {allSites.filter(AS => AS.site_name == ID.site_name)[0].site_contact.last_name}</td> */}
																    		{/* <td>{allSites.filter(AS => AS.site_name == ID.site_name)[0].site_contact.phone}</td> */}
																    	</tr>
															    	)
															    })}
														    </>
										    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
										    			}
										    		</>
										    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}

							{indNav === "Records" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
										<div className={`${styles.create_first2} ${styles.cw_pricing}`}>
											<h1 className={`${styles.medium_font}`}>Download previous delivery run records</h1>

											<button
												className={styles.indsitebtn}
												onClick={() => handleDeliveryRecords('open')}
											>
												<i className="fa-solid fa-plus"></i>
												<p className={styles.smaller_font}>Download record</p>
											</button>
										</div>
									</div>
								</div>
							}

							{indNav == "Box Models" &&
								<div className={styles.sites_tablecontainer}>
									{!showIndModel 
									?
										<>
											<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
												<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
													<div className={styles.sites_search}>
														<i className="fa-solid fa-magnifying-glass"></i>
														<input
														    type="text"
														    className={styles.smaller_font}
														    placeholder="Search for any of the column headings..."
														    onKeyUp={(event) => searchControl(event, "ind_table_model")}
														/>
													</div>
												</div>
											</div>

											<div className={styles.table_container}>
												<table className={styles.sites_table} id="ind_table_model">
												    <thead className={styles.sitesind_thead}>
												      <tr className={styles.table_rowth}>
												      	<th>Site</th>
												      	<th>Suburb</th>
												      </tr>
												    </thead>
												    <tbody className={styles.indtbody}>
												    	{indDelivery.items
												    		?
												    		<>
												    			{indDelivery.items.length > 0
												    				? 
												    				<>
													    				{indDelivery.items.map((ID) => {
													    					return (
																		    	<tr className={styles.sitesind_tr} onClick={() => indPopUpControls('toggle_model')}>
																		    		<td>{ID.site_name}</td>
																		    		<td>{allSites.filter(AS => AS.site_name == ID.site_name)[0].site_location.city}</td>
																		    	</tr>
																	    	)
																	    })}
																    </>
												    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
												    			}
												    		</>
												    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
												    	}
												    </tbody>
												</table>
											</div>
										</>
									:
				 						<div>
				 							<div className={styles.box_layout}>
				 				    			<button className={`${styles.site_status} ${boxModelShowing === "1" ? styles.box_active : styles.status_active}`} onClick={() => indPopUpControls('nav_boxes', '1')}>
				 				    				<p className={styles.smaller_font}>Box 1</p>
				 				    			</button>
				 
				 				    			<button className={`${styles.site_status} ${boxModelShowing === "2" ? styles.box_active : styles.status_active}`} onClick={() => indPopUpControls('nav_boxes', '2')}>
				 				    				<p className={styles.smaller_font}>Box 2</p>
				 				    			</button>

				 				    			<button className={styles.closeModelPopup} onClick={() => indPopUpControls('toggle_model')}><i className="fa-solid fa-arrow-left-long"></i></button>
				 							</div>
				 
				 							{boxModelShowing &&
												<iframe src={`/box${boxModelShowing}.html`}  style={{ width: '100%', height: '500px', border: 'none' }} />
											}
										</div>
									}
								</div>
							}

						</div>
				}
			</ResizableDiv>
		</div>
	)
}

export default PopUpIndDelivery;