module.exports = (ordersPassed, allInventory, allTiers) => {
    let pricingArr = {}

    for (let sOrder of ordersPassed) {
        let specificOrder = { ...sOrder }

        let foundSite = {...specificOrder.site}
        let fruitTiers;
        let procalTiers;
        let findSiteFT = allTiers.filter(tier => (tier.type === "Fruit" && tier.location === "Site") && tier.siteLink.toString() === specificOrder._id.toString())
        let findSitePT = allTiers.filter(tier => (tier.type === "Milk" && tier.location === "Site") && tier.siteLink.toString() === specificOrder._id.toString())

        let returnObj = {}

        if (findSiteFT.length > 0) {
            fruitTiers = findSiteFT[0].items
        } else {
            if (foundSite.hasOwnProperty('supplier_platform') && (foundSite.supplier_platform === "Yordar" || foundSite.supplier_platform === "EatFirst")) {
                switch (foundSite.supplier_platform) {
                    case 'Yordar':
                        fruitTiers = allTiers.filter(tier => tier.type === "Fruit" && tier.location === "Yordar")[0].items
                        break;
                    case 'EatFirst':
                        fruitTiers = allTiers.filter(tier => tier.type === "Fruit" && tier.location === "EatFirst")[0].items
                        break;
                }
            } else {
                fruitTiers = allTiers.filter(tier => tier.type === "Fruit" && tier.location === "General")[0].items
            }
        }

        if (findSitePT.length > 0) {
            procalTiers = findSitePT[0].items
        } else {
            if (foundSite.hasOwnProperty('supplier_platform') && (foundSite.supplier_platform === "Yordar" || foundSite.supplier_platform === "EatFirst")) {
                switch (foundSite.supplier_platform) {
                    case 'Yordar':
                        procalTiers = allTiers.filter(tier => tier.type === "Milk" && tier.location === "Yordar")[0].items
                        break;
                    case 'EatFirst':
                        procalTiers = allTiers.filter(tier => tier.type === "Milk" && tier.location === "EatFirst")[0].items
                        break;
                }
            } else {
                procalTiers = allTiers.filter(tier => tier.type === "Milk" && tier.location === "General")[0].items
            }   
        }

        let totalAmount = 0;
        let allExtraItems = allInventory.filter(inv => inv.category === "Fruit" && inv.subcategory === "Extra Items").map(inv => inv._id.toString())


//         let ordersGrouped = [specificOrder]
//         let siteGroup = foundAcc.hasOwnProperty('site_groups') && foundAcc.site_groups.find(siteGroup => siteGroup.some(site => site.toString() === foundSite._id.toString()));
//         if (siteGroup) {
//             let siteGroupMapped = siteGroup.map(site => site.toString()).filter(site => site !== foundSite._id.toString())
//             let siteFoundFromGroup = allSites.filter(site => siteGroupMapped.includes(site._id.toString()))
// 
//             for (let site of siteFoundFromGroup) {
//                 let ordersFoundFromSite = allOrders.filter(order => site.orders.map(order => order.order.toString()).includes(order._id.toString()))
// 
//                 for (let order of ordersFoundFromSite) {
//                     if ((order.delivery_date && new Date(order.delivery_date).toISOString().split("T")[0] === specificOrder.delivery_date && new Date(specificOrder.delivery_date).toISOString().split("T")[0]) && (order.order_type === specificOrder.order_type) && (specificOrder.hasOwnProperty('box_type') ? specificOrder.box_type === order['box_type'] : true)) {
//                         ordersGrouped.push(order)
//                     }
//                 }
//             }
//         }
// 
//         if (ordersGrouped.length > 1) {
//             let combinedItems = ordersGrouped.flatMap(orderGroup => orderGroup.items);
//             specificOrder['items'] = combinedItems
//         }
        

        if (specificOrder.order_type === "Fruit") {
            let flattenedItems = specificOrder.items.reduce((acc, box) => acc.concat(box), []);
            let orderExtras = flattenedItems.filter(item => allExtraItems.includes(item.item.toString()))
            let orderExtrasMapped = orderExtras.map(item => item.item.toString())
            let orderWithoutExtras = flattenedItems.filter(item => !orderExtrasMapped.includes(item.item.toString()))
            let orderWithoutExtrasQuantity = orderWithoutExtras.reduce((acc, { quantity }) => acc + parseInt(quantity), 0);

            if (orderWithoutExtras.length < 3) {
                returnObj['extras'] = {}

                for (let itemO of orderWithoutExtras) {
                    let inventoryItemO = allInventory.find((inventory) => inventory._id.toString() === itemO.item.toString());

                    if (inventoryItemO) {
                        returnObj['extras'][inventoryItemO['item_name']] = {
                            unit_price: parseFloat(inventoryItemO.sale_price) || 0,
                            quantity: parseFloat(itemO.quantity),
                            total_price: (inventoryItemO['sale_price']) * parseFloat(itemO.quantity)
                        }

                        totalAmount += (inventoryItemO['sale_price']) * parseFloat(itemO.quantity);
                    }
                }
            } else {
                let selectedBreakdown = null;
                for (let tier of fruitTiers) {
                    const [min, max] = tier.breakdown.split('-').map(val => val.replace('pcs', '').trim());

                    const minQuantity = parseInt(min, 10);
                    const maxQuantity = max ? parseInt(max, 10) : Infinity;
            
                    if (orderWithoutExtrasQuantity >= minQuantity && orderWithoutExtrasQuantity <= maxQuantity) {
                        selectedBreakdown = tier;
                    }
                }

                let pricePP;
                if (specificOrder.box_type === "Flexi") {
                    pricePP = selectedBreakdown['flexi'];
                } else {
                  pricePP = selectedBreakdown['pc_price'];

                  returnObj['box'] = {
                    unit_price: pricePP,
                    quantity: orderWithoutExtrasQuantity,
                  }
                }

                returnObj['unit_price'] = pricePP

                // calculate total for order without extras
                for (let item of orderWithoutExtras) {
                    let inventoryItem = allInventory.find((inventory) => inventory._id.toString() === item.item.toString());
                    if (inventoryItem) {
                        totalAmount += pricePP * parseFloat(item.quantity);
                    }
                }

                // calculate total for order extras
                if (orderExtras.length > 0) {
                    returnObj['extras'] = {}
                }

                for (let item of orderExtras) {
                    let inventoryItem = allInventory.find((inventory) => inventory._id.toString() === item.item.toString());

                    if (inventoryItem) {
                        returnObj['extras'][inventoryItem['item_name']] = {
                            unit_price: parseFloat(inventoryItem.sale_price) || 0,
                            quantity: parseFloat(item.quantity),
                            total_price: (parseFloat(inventoryItem.sale_price) || 0) * parseFloat(item.quantity)
                        }

                        totalAmount += (parseFloat(inventoryItem.sale_price) || 0) * parseFloat(item.quantity);
                    }
                }
            }
        } else if (specificOrder.order_type === "Milk") {
            let procalItems = procalTiers.map(procal => procal.item)
            let procalTotal = 0;
            returnObj['extras_milk'] = {}

            for (let item of specificOrder.items) {
                let inventoryItem = allInventory.find((inventory) => inventory._id.toString() === item.item.toString());
                if (inventoryItem) {
                    if (procalItems.includes(inventoryItem.item_name)) {
                        if (inventoryItem.item_name.includes('2L')) {
                            procalTotal += parseFloat(item.quantity) * 2
                        } else {
                            procalTotal += parseFloat(item.quantity)
                        }   
                    }
                }
            }

            const tiers = [
                { threshold: 101, name: 'tier5' },
                { threshold: 61, name: 'tier4' },
                { threshold: 31, name: 'tier3' },
                { threshold: 21, name: 'tier2' },
                { threshold: 9, name: 'tier1' },
                { threshold: 0, name: 'tier1' }
            ];
            let tier = tiers.find(t => procalTotal >= t.threshold)?.name;

            for (let item of specificOrder.items) {
                let inventoryItem = allInventory.find((inventory) => inventory._id.toString() === item.item.toString());

                if (inventoryItem) {
                    if (procalItems.includes(inventoryItem.item_name)) {
                        returnObj['extras_milk'][inventoryItem['item_name']] = {
                            unit_price: procalTiers.filter(procal => procal.item === inventoryItem.item_name)[0][tier],
                            quantity: parseFloat(item.quantity),
                            total_price: procalTiers.filter(procal => procal.item === inventoryItem.item_name)[0][tier] * parseFloat(item.quantity)
                        }

                        totalAmount += procalTiers.filter(procal => procal.item === inventoryItem.item_name)[0][tier] * parseFloat(item.quantity)
                    } else {
                        returnObj['extras_milk'][inventoryItem['item_name']] = {
                            unit_price: parseFloat(inventoryItem.sale_price),
                            quantity: parseFloat(item.quantity),
                            total_price: parseFloat(inventoryItem.sale_price) * parseFloat(item.quantity)
                        }

                        totalAmount += parseFloat(inventoryItem.sale_price) * parseFloat(item.quantity);
                    }
                }
            }
        } else {
            returnObj['extras_other'] = {}

            for (const item of specificOrder.items) {
                const inventoryItem = allInventory.find((inventory) => inventory._id.toString() === item.item.toString());
                if (inventoryItem) {
                    returnObj['extras_other'][inventoryItem['item_name']] = {
                        unit_price: parseFloat(inventoryItem.sale_price),
                        quantity: parseFloat(item.quantity),
                        total_price: parseFloat(inventoryItem.sale_price) * parseFloat(item.quantity)
                    }

                    totalAmount += parseFloat(inventoryItem.sale_price) * parseFloat(item.quantity);
                }
            }
        }

        let totalAmountParsed = parseFloat(totalAmount.toFixed(2))
        returnObj['price'] = totalAmountParsed

        pricingArr[specificOrder['order_code']] = returnObj
    }

    return pricingArr;
};