import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Warehouse.module.css"

import ReactAutocomplete from "react-google-autocomplete";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const PopUpDelivery = ({ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleMenuItem, menuItemArr, handleMenuArr, onDragEndRun, handleSeasonalItem, menuSeasonalItem, seasonalOptions, changeDeliveryNav, deliveryNav, handleNewDeliveryData, newDeliveryData, runOptions, handleRunArr, runArr, handleRunItem, allSites, runOptionsOG, indLoading }) => {
	return (
		<div className={`${popUpPage == 1 && styles.popup_container} ${(popUpPage == 2 || popUpPage == 3) && styles.fixed_container_delivery}`}>
			{indLoading
				?
					<div className={styles.loadpage_container_white}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				:
					<>
						<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

						{popUpPage == 1 &&
							<>
								<h1 className={styles.medium_font}>Delivery details</h1>
								<p className={styles.smaller_font}>Please enter the specific delivery details to create.</p>

								<div className={styles.popup_form}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Run day</label>
										<Select
											closeMenuOnSelect={true}
											options={[{label: 'Monday', value: 'Monday'}, {label: 'Tuesday', value: 'Tuesday'}, {label: 'Wednesday', value: 'Wednesday'}, {label: 'Thursday', value: 'Thursday'}, {label: 'Friday', value: 'Friday'}, {label: 'Public Holiday', value: 'Public Holiday'}]}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
												}),
											}}
											onChange={(selected) => handleNewDeliveryData("run_day", selected)}
											value={{label: newDeliveryData["run_day"], value: newDeliveryData["run_day"]}}
											isDisabled={newDeliveryData.edit && newDeliveryData.edit}
										/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Run number</label>
										<input type="text" value={newDeliveryData && newDeliveryData['run_number']} disabled={true}/>
									</div>
								</div>
							</>
						}

						{popUpPage == 2 &&
							<>
								<h1 className={styles.medium_font}>Delivery details</h1>
								<p className={styles.smaller_font}>Please enter the specific delivery details to create.</p>

								<div className={`${styles.indsite_nav} ${styles.mt_25}`}>
									<div className={styles.sitenav}>
										<button className={`${styles.sitenav_box} ${deliveryNav == 'Run' && styles.sitenav_active}`} onClick={() => changeDeliveryNav('Run')}><p>Run</p></button>
										<button className={`${styles.sitenav_box} ${deliveryNav == 'Queue' && styles.sitenav_active}`} onClick={() => changeDeliveryNav('Queue')}><p>Queue</p></button>
									</div>
								</div>

								{deliveryNav == "Run" &&
									<div className={`${styles.draggableContainerDelivery}`}>
										<DragDropContext onDragEnd={onDragEndRun}>
											<Droppable droppableId="menuItems">
												{(provided) => (
													<div {...provided.droppableProps} ref={provided.innerRef}>
														{runArr.map((RA, index) => (
															<Draggable key={RA.id} draggableId={RA.id.toString()} index={RA.id} id={RA.id}>
																{(provided) => (
																	<div ref={provided.innerRef} {...provided.draggableProps} className={`${(newDeliveryData.run_day === "Public Holiday") ? styles.popup_form_delivery_extra : styles.popup_form_delivery}`}>
																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Site</label>
																			<Select
																				closeMenuOnSelect={true}
																				options={formatOptions(Object.keys(runOptions))}
																				styles={{
																					control: (baseStyles, state) => ({
																						...baseStyles,
																						boxShadow: 'none !important',
																					   '&:hover': {
																					       borderColor: '#fce7eb !important'
																					    },
																						borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
																					}),
																				}}
																				onChange={(selected) => handleRunArr("site", selected.value, RA.id)}
																				value={{label: RA.site, value: RA.site}}
																			/>
																		</div>

																		{newDeliveryData.run_day === "Public Holiday" &&
																			<div className={styles.popup_formbox}>
																				<label className={styles.smaller_font}>Day</label>
																				<Select
																					closeMenuOnSelect={true}
																					options={(RA['site'] && Object.keys(runOptionsOG[RA['site']]).length > 0) ? formatOptions(Object.keys(runOptionsOG[RA['site']])) : formatOptions([])}
																					styles={{
																						control: (baseStyles, state) => ({
																							...baseStyles,
																							boxShadow: 'none !important',
																						   '&:hover': {
																						       borderColor: '#fce7eb !important'
																						    },
																							borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
																						}),
																					}}
																					onChange={(selected) => handleRunArr("day", selected.value, RA.id)}
																					value={{label: RA.day, value: RA.day}}
																				/>
																			</div>
																		}

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Fruit</label>
																			<input type="text" disabled={true} value={RA.site ? RA.fruit === "FRUIT" ? "YES" : RA.fruit === "/" ? "-" : "NO" : '-'}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Milk</label>
																			<input type="text" disabled={true} value={RA.site ? RA.milk === "MILK" ? "YES" : RA.milk === "/" ? "-" : "NO" : '-'}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Bread</label>
																			<input type="text" disabled={true} value={RA.site ? RA.bread === "BREAD" ? "YES" : RA.bread === "/" ? "-" : "NO" : '-'}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Coffee</label>
																			<input type="text" disabled={true} value={RA.site ? RA.coffee === "COFFEE" ? "YES" : RA.coffee === "/" ? "-" : "NO" : '-'}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Cakes</label>
																			<input type="text" disabled={true} value={RA.site ? RA.cakes === "CAKES" ? "YES" : RA.cakes === "/" ? "-" : "NO" : '-'}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Catering</label>
																			<input type="text" disabled={true} value={RA.site ? RA.catering === "CATERING" ? "YES" : RA.catering === "/" ? "-" : "NO" : '-'}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Other</label>
																			<input type="text" onChange={(input) => handleRunArr("other", input.target.value, RA.id)} value={RA.other}/>
																		</div>

																		<div className={styles.popup_formbox}>
																			<label className={styles.smaller_font}>Tools</label>
																			<div className={styles.form_controls}>
																				<i className={`fa-solid fa-up-down ${styles.medium_font} ${styles.dragBtn}`} {...provided.dragHandleProps}></i>
																				<button onClick={() => handleRunItem('delete', RA.id)}><i className={`fa-solid fa-trash-can ${styles.medium_font} ${styles.ml_7}`}></i></button>
																			</div>
																		</div>
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>

										<div className={styles.menuControls}>
											<button type="button" className={styles.addBtn} onClick={() => handleRunItem('add')}>
												<i className="fa-solid fa-plus"></i>
											</button>
										</div>
									</div>
								}

								{deliveryNav == "Queue" &&
									<div className={`${styles.draggableContainerDelivery}`}>
										<div className={styles.formbox_grid}>
											{Object.keys(runOptions).length > 0 && Object.keys(runOptions).filter(option => option !== "").map((RO, index) => (
												<div className={styles.popup_form_delivery_confirm}>
													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Site</label>
														<input type="text" value={RO} disabled={true}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Fruit</label>
														<input type="text" disabled={true} value={runOptions[RO].Fruit ? "Fruit" : 'NO'}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Milk</label>
														<input type="text" disabled={true} value={runOptions[RO].Milk ? "Milk" : 'NO'}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Bread</label>
														<input type="text" disabled={true} value={runOptions[RO].Bread ? "YES" : 'NO'}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Coffee</label>
														<input type="text" disabled={true} value={runOptions[RO].Coffee ? "YES" : 'NO'}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Cakes</label>
														<input type="text" disabled={true} value={runOptions[RO].Cakes ? "YES" : 'NO'}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Catering</label>
														<input type="text" disabled={true} value={runOptions[RO].Catering ? "YES" : 'NO'}/>
													</div>

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Other</label>
														<input type="text" disabled={true} value={runOptions[RO].other}/>
													</div>
												</div>
											))}
										</div>
									</div>
								}
							</>
						}

						{popUpPage == 3 &&
							<>
								<h1 className={styles.medium_font}>Confirm details</h1>
								<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

								<div className={`${styles.draggableContainerDelivery} ${styles.mt_25}`}>
									<div className={styles.formbox_grid}>
										{runArr.map((RA, index) => (
											<div className={styles.popup_form_delivery_confirm}>
												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Site</label>
													<input type="text" value={RA.site} disabled={true}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Address</label>
													<input type="text" disabled={true} value={RA.address}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Fruit</label>
													<input type="text" disabled={true} value={RA.fruit === "FRUIT" ? "Yes" : "No"}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Milk</label>
													<input type="text" disabled={true} value={RA.milk === "MILK" ? "Yes" : "No"}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Bread</label>
													<input type="text" disabled={true} value={RA.bread === "BREAD" ? "Yes" : "No"}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Coffee</label>
													<input type="text" disabled={true} value={RA.coffee === "COFFEE" ? "Yes" : "No"}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Cakes</label>
													<input type="text" disabled={true} value={RA.cakes === "CAKES" ? "Yes" : "No"}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Catering</label>
													<input type="text" disabled={true} value={RA.catering === "CATERING" ? "Yes" : "No"}/>
												</div>

												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Other</label>
													<input type="text" disabled={true} value={RA.other}/>
												</div>
											</div>
										))}
									</div>
								</div>
							</>
						}

						{validationError &&
							<div className={`${styles.notification} ${styles.validationError} ${styles.customValidation}`}>
								<p className={styles.smaller_font}>{validationError}</p>
							</div>
						}

						<div className={`${styles.popUpControls} ${styles.controls_bottom}`}>
							<div className={styles.popUpControlsContainer}>
					      		{popUpPage != 1 &&
					      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
					      		}

					      		{popUpPage != 3 &&
					      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
					      		}

					      		{popUpPage == 3 &&
					      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
					       		}
				       		</div>
			       		</div>
       			</>
       		}
		</div>
	)
}

export default PopUpDelivery;